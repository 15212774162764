/* src/styles/ManageModels.css */

.manage-models-container {
    padding: 20px;
}

.manage-models-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.model-list {
    list-style: none;
    padding: 0;
}

.model-list li {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.model-list li:last-child {
    border-bottom: none;
}

.model-list button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.model-list button:hover {
    background-color: #2980b9;
}

.model-form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.model-form input {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.model-form button {
    background-color: #2ecc71;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
}

.model-form button:hover {
    background-color: #27ae60;
}
