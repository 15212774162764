/* Container Styles */
.auth-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 30px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Header Styles */
.auth-header {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  color: #333;
  margin-bottom: 25px;
}

/* Form Styles */
.auth-form {
  display: flex;
  flex-direction: column;
}

.auth-form-group {
  margin-bottom: 20px;
}

.auth-form-group label {
  font-weight: 600;
  margin-bottom: 8px;
  display: block;
  color: #555;
}

.auth-input {
  width: 100%;
  padding: 12px 15px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 6px;
  transition: border-color 0.2s;
}

.auth-input:focus {
  border-color: #007bff;
  outline: none;
}

/* Button Styles */
.auth-button {
  background-color: #007bff;
  color: white;
  font-size: 16px;
  padding: 12px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 20px; /* Space between buttons */
  transition: background-color 0.3s ease;
}

.auth-button:hover {
  background-color: #0056b3;
}

/* Social Button Styles */
.auth-social-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  display: flex;  /* Align icon and text */
  align-items: center;  /* Center icon and text vertically */
  justify-content: center;  /* Center icon and text horizontally */
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 25px;  /* Adjust spacing for social button */
  transition: background-color 0.3s ease;
}

.auth-social-button.google {
  background-color: #db4437;
  color: white;
}

.auth-social-button.google:hover {
  background-color: #c23320;
}

.auth-social-button.github {
  background-color: #333;
  color: white;
}

.auth-social-button.github:hover {
  background-color: #1a1a1a;
}

/* Footer Styles */
.auth-footer {
  text-align: center;
  margin-top: 30px;  /* Increase spacing from buttons to footer */
}

.auth-footer span {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.auth-footer span:hover {
  color: #0056b3;
}

/* Error Message Styling */
.error-message {
  color: #dc3545;
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
}
