.execution-details {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.execution-info {
  width: 60%;
}

.task-details {
  width: 100%;
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  background-color: #fff;
}

th, td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: left;
}

th {
  background-color: #f4f4f4;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
}

td {
  font-size: 14px;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

button {
  padding: 8px 12px;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.log-display {
  margin-left: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  width: 300px;
  max-height: 400px;
  overflow-y: auto;
}

.log-display h3 {
  margin-top: 0;
  font-size: 16px;
  font-weight: bold;
}

.log-display pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 4px;
  font-size: 12px;
}

/* Artifacts list styling */
ul {
  padding-left: 20px;
  list-style-type: disc;
}

ul li {
  margin-bottom: 5px;
}

ul li a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

ul li a:hover {
  color: #0056b3;
}

/* Execution control buttons */
.taskflow-controls {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.execution-logs, .execution-artifacts {
  margin-top: 20px;
}

.execution-logs pre {
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}
