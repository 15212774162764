.profile-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }
  
  .profile-header {
    font-size: 28px;
    margin-bottom: 30px;
  }
  
  .profile-details {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .profile-detail {
    font-size: 18px;
  }
  
  .profile-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .profile-button:hover {
    background-color: #0056b3;
  }
  