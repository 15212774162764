/* src/styles/Dashboard.css */

.dashboard-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .dashboard-container h2 {
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .flowform {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .flowform input {
    width: 70%;
    padding: 10px;
    font-size: 16px;
  }
  
  .flowform button {
    width: 25%;
    padding: 10px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .flowform button:hover {
    background-color: #2980b9;
  }
  
  .flowlist h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  .flow-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .flow-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: #ecf0f1;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .flow-info {
    flex-grow: 1;
  }
  
  .flow-name {
    font-size: 20px;
    color: #2c3e50;
    text-decoration: none;
    font-weight: bold;
  }
  
  .flow-name:hover {
    text-decoration: underline;
  }
  
  .delete-flow-btn {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .delete-flow-btn:hover {
    background-color: #c0392b;
  }
  
  .create-flow a {
    display: flex;
    align-items: center;  /* Align icon and text */
    text-decoration: none;
    font-size: 18px;
    color: #2c3e50;
  }
  
  .create-flow a:hover {
    text-decoration: bold;
  }

  @media (max-width: 768px) {
    .dashboard-container {
      padding: 10px;
      max-width: 100%;
    }
  
    .flow-list {
      flex-direction: column;
    }
  
    .flow-item {
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
    }
  }
  