.create-flow-container {
  padding: 20px;
  background-color: #f8f9fa; /* Light background for contrast */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  max-width: 500px;
  margin: 0 auto; /* Center align */
}

.create-flow-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #2c3e50;
}

.flowform {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Add space between inputs and button */
  align-items: center; /* Center align content */
}

.flowform input {
  padding: 10px;
  font-size: 16px;
  width: 100%;
  max-width: 400px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05); /* Soft inner shadow */
}

.flowform input:focus {
  border-color: #3498db;
  outline: none;
  box-shadow: 0 0 4px rgba(52, 152, 219, 0.5); /* Highlight focus */
}

.flowform button {
  padding: 12px 30px;
  font-size: 16px;
  background-color: #3498db; /* Brighter button color */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.flowform button:hover {
  background-color: #2980b9; /* Darker on hover */
}

.flowform button:active {
  background-color: #1a5276; /* Even darker on active */
  transform: scale(0.98); /* Slight scale down */
}


@media (max-width: 768px) {
  .create-flow-container {
    padding: 15px;
    margin: 10px; /* Reduce margin on smaller screens */
    box-shadow: none; /* Simplify shadows on mobile for better performance */
    border: 1px solid #ddd; /* Add a subtle border */
  }

  .flowform {
    gap: 10px; /* Reduce gap between elements */
  }

  .flowform input {
    font-size: 14px; /* Slightly smaller font for smaller screens */
    padding: 8px; /* Adjust padding for touch */
    max-width: 100%; /* Ensure inputs don't overflow */
  }

  .flowform button {
    font-size: 14px; /* Adjust button font size */
    padding: 10px; /* Keep button touch-friendly */
    width: 100%; /* Make the button span the full width */
    max-width: 400px;
  }
}
