/* src/App.css */

.app-container {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 250px;
  background-color: #2c3e50;
  color: white;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  margin-bottom: 10px;
}

.sidebar ul li a {
  color: white;
  text-decoration: none;
}

.sidebar ul li a:hover {
  text-decoration: underline;
}

.content {
  margin-left: 280px; /* Make sure this matches the width of the sidebar */
  padding: 20px;
  flex-grow: 1;
  background-color: #ecf0f1;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
  .hamburger-menu {
    display: block;
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 1000;
  }
  .content {
    margin-left: 0; /* Remove the left margin when sidebar is hidden */
  }
}
