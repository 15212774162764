/* src/styles/ManagePersonas.css */

.manage-personas-container {
    padding: 20px;
}

.manage-personas-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.persona-list {
    list-style: none;
    padding: 0;
}

.persona-list li {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.persona-list li:last-child {
    border-bottom: none;
}

.persona-list button {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.persona-list button:hover {
    background-color: #c0392b;
}

.persona-form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.persona-form input {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.persona-form button {
    background-color: #9b59b6;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
}

.persona-form button:hover {
    background-color: #8e44ad;
}

.persona-icon {
    margin-right: 10px;
    color: #555; /* Adjust as needed */
  }
  
  .manage-personas-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .persona-form {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .form-buttons {
    display: flex;
    gap: 8px;
  }
  
  .btn-small {
    padding: 6px 12px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .btn-clear {
    background-color: #f44336;
    color: white;
  }
  
  .persona-list-container {
    max-height: 400px;
    overflow-y: auto;
  }
  
  .persona-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .persona-table th,
  .persona-table td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .persona-icon {
    font-size: 20px;
  }
  