/* Sidebar Styles */
.sidebar {
  width: 250px;
  height: 100vh;
  background-color: #2c3e50;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
}

.sidebar h2, .sidebar h3 {
  color: white;
}

.sidebar h2 {
  margin-bottom: 20px;
  font-size: 24px;
}

.sidebar h3 {
  margin-top: 20px;
  font-size: 20px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  margin-bottom: 20px;
}

.sidebar ul li a {
  display: flex;
  align-items: center;  /* Align icon and text */
  text-decoration: none;
  font-size: 18px;
  color: white;
}

.sidebar ul li a:hover {
  text-decoration: bold;
}

.sidebar-icon {
  margin-right: 10px;  /* Space between icon and text */
}

.flow-link {
  font-size: 16px;
  color: #ecf0f1;
}

.flow-link:hover {
  text-decoration: bold;
}

.avatar-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.avatar-icon {
  font-size: 24px;
  color: #7f8c8d;
}

/* User Dropdown Menu */
.user-menu {
  position: absolute;
  bottom: 70px;
  left: 0;
  background-color: #34495e;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.user-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.user-menu ul li {
  margin-bottom: 10px;
}

.user-menu ul li a {
  color: white;
  text-decoration: none;
}

.user-menu ul li a:hover {
  text-decoration: underline;
}

.user-menu ul li:hover {
  cursor: pointer;
}

/* Ensure the user-avatar is properly aligned within the sidebar */
.user-avatar {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 30px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; /* Adjust position relative to its container */
  margin-top: auto; /* Pushes the avatar to the bottom */
}

.sidebar {
  width: 250px;
  height: 100vh;
  background-color: #2c3e50;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto; /* Add scrolling if content exceeds screen height */
}

.logo-image {
  width: 50px;  /* Adjust width as needed */
  height: auto;
}

/* Hamburger Menu */
.hamburger-menu {
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: black; /* Make the hamburger black */
  cursor: pointer;
  z-index: 1001; /* Ensure it's above other elements */
  position: fixed;
  top: 20px;
  left: 20px;
}

/* Sidebar Open */
.sidebar.open {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100vh;
  background-color: #2c3e50;
  z-index: 1000;
  transition: transform 0.3s ease;
}

/* Close Sidebar Button */
.close-sidebar {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.avatar-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.avatar-icon {
  font-size: 36px;
  color: white;
}

/* Sidebar Styles */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100vh;
  background-color: #2c3e50;
  color: white;
  padding: 20px;
  overflow-y: auto; /* Enable scrolling if content overflows */
}

.sidebar h2, .sidebar h3 {
  color: white;
}

.sidebar h2 {
  margin-bottom: 20px;
  font-size: 24px;
}

.sidebar h3 {
  margin-top: 20px;
  font-size: 20px;
}

.sidebar ul {
  padding: 0;
  margin: 20px 0;
  list-style: none;
}

.sidebar ul li {
  margin-bottom: 15px;
}

.sidebar ul li a {
  display: flex;
  align-items: center; /* Align icon and text */
  text-decoration: none;
  font-size: 18px;
  color: white;
}

.sidebar ul li a:hover {
  text-decoration: underline;
}

.sidebar-icon {
  margin-right: 10px; /* Space between icon and text */
}

.flow-link {
  font-size: 16px;
  color: #ecf0f1;
}

.flow-link:hover {
  text-decoration: underline;
}

/* Hamburger Menu */
.hamburger-menu {
  display: none; /* Default hidden */
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: black; /* Black for better visibility */
  cursor: pointer;
  z-index: 1001; /* Ensure it's above other elements */
  position: fixed;
  top: 20px;
  left: 20px;
}

@media (max-width: 768px) {
  .hamburger-menu {
    display: block; /* Show only on mobile */
  }
}

/* Sidebar Open */
.sidebar.open {
  transition: transform 0.3s ease; /* Smooth opening/closing */
}

/* Close Sidebar Button */
.close-sidebar {
  display: none; /* Default hidden */
  background-color: transparent;
  border: none;
  color: white;
  font-size: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .close-sidebar {
    display: block; /* Show only on mobile */
  }
}

/* User Avatar */
.user-avatar {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.avatar-icon {
  font-size: 36px;
  color: white;
}

/* User Dropdown Menu */
.user-menu {
  position: absolute;
  bottom: 70px;
  left: 0;
  background-color: #34495e;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.user-menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.user-menu ul li {
  margin-bottom: 10px;
}

.user-menu ul li a {
  color: white;
  text-decoration: none;
}

.user-menu ul li a:hover {
  text-decoration: underline;
}

.user-menu ul li:hover {
  cursor: pointer;
}

/* Subscription Information */
.subscription-level {
  position: absolute;
  bottom: 20px; /* Place it below the user-profile-container */
  left: 20px;
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subscription-level button {
  background: #c69500;
  border: none;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.logo-image {
  width: 50px;  /* Adjust width as needed */
  height: auto;
}

/* Sidebar default */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100vh;
  background-color: #2c3e50;
  color: white;
  padding: 20px;
  overflow-y: auto; /* Enable scrolling if content exceeds screen height */
}

/* Sidebar behavior for mobile */
@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%); /* Hide off-screen */
    transition: transform 0.3s ease; /* Smooth slide-in effect */
  }

  .sidebar.open {
    transform: translateX(0); /* Slide into view */
  }
}

/* Hamburger menu visible only in mobile mode */
.hamburger-menu {
  display: none; /* Hidden by default */
}

@media (max-width: 768px) {
  .hamburger-menu {
    display: block; /* Show only on mobile */
    position: fixed;
    top: 20px;
    left: 20px;
    font-size: 24px;
    color: black;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}

/* Close button visible only in mobile mode */
.close-sidebar {
  display: none; /* Hidden by default */
}

@media (max-width: 768px) {
  .close-sidebar {
    display: block; /* Show only on mobile */
    background-color: transparent;
    border: none;
    color: white;
    font-size: 24px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}

/* Logo Image */
.logo-image {
  max-width: 100%;
  height: auto;
  width: 40px; /* Adjust icon size */
  display: block;
  margin: 0 auto;
}

/* User Profile Container */
.user-profile-container {
  position: absolute;
  bottom: 70px; /* Move the container up slightly */
  left: 20px;
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.avatar-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.avatar-icon {
  font-size: 24px;
  color: #7f8c8d;
}

.subscription-icon-button {
  display: flex;
  align-items: center;
  background: #c69500;
  color: #212529;
  border: none;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, box-shadow 0.3s;
  margin-bottom: 40px;
  margin-top: 10px;
}

.subscription-icon-button svg {
  margin-right: 8px; /* Space between icon and text */
  font-size: 1.2em;
}

.subscription-icon-button:hover {
  background-color: #e0a800;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow */
}

.subscription-icon-button:focus {
  outline: none;
  background-color: #c69500;
  box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.5);
}

