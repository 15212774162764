/* General container styles */
.taskflow-container {
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: 100%;
  height: 90vh;
  background-color: #f4f4f4;
}

/* Control buttons */
.taskflow-controls {
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
}

.start-button,
.pause-button,
.resume-button,
.stop-button {
  padding: 8px 15px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
}

.start-button {
  background-color: #28a745;
}

.pause-button {
  background-color: #ffc107;
}

.resume-button {
  background-color: #17a2b8;
}

.stop-button {
  background-color: #dc3545;
}

.start-button:disabled,
.pause-button:disabled,
.resume-button:disabled,
.stop-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.taskflow-main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
}

.task-canvas {
  flex-grow: 1;
  background-color: #f4f4f4;
  position: relative;
}

/* Resize handle */
.resize-handle {
  height: 5px;
  background-color: #ddd;
  cursor: ns-resize;
  z-index: 1002;
}

/* History section */
.history-section {
  background-color: #fff;
  border-top: 1px solid #ddd;
  overflow-y: auto;
  z-index: 1001;
}

.history-section table {
  width: 100%;
  border-collapse: collapse;
}

.history-section th,
.history-section td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

/* Task form styles */
.taskform {
  position: absolute;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  width: 320px;
  max-width: 100%;
}

.taskform input,
.taskform textarea,
.taskform select {
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 3px;
  border: 1px solid #ccc;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.taskform button {
  align-self: flex-end;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.taskform button:hover {
  background-color: #218838;
}

.cancel-button {
  background-color: #dc3545;
  margin-left: 10px;
  margin-top: 10px;
  padding: 5px 10px;
  font-size: 14px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button:hover {
  background-color: #c82333;
}

.resize-handle{
  margin-top: 20px;
}

.add-task-button {
  z-index: 999;
  position: relative;
}

.edit-button {
  margin: 5px;
}
.delete-button {
  margin: 5px;
}

.react-flow__node {
  border-radius: 4px;
}

.custom-node {
  position: relative;
  border-radius: 5px;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  text-align: center;
  resize: both; /* Optional if you want a resizable UI hint */
}

.resize-corner {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  cursor: nwse-resize;
  background-color: #ddd;
}
